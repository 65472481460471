import nprogress from '@utils//nprogress';
import config from '@config';
import store from '@store';
import router from '@router';

const axios = require('axios').default;

const http = axios.create({
  baseURL: config.http.url,
  headers: config.http.defaultRequest.headers,
});
/**
 * Add a request and response interceptor
 */
const beforeRequestSuccess = (configurate) => {
  // eslint-disable-next-line
  configurate.headers.Authorization = `Bearer ${store.getters['auth/getToken']}`;
  nprogress.start();

  store.dispatch('global/setAppLoading', true);

  return configurate;
};

const beforeRequestError = (error) => {
  nprogress.start();
  return Promise.reject(error);
};

const onSuccess = (response) => {
  store.dispatch('global/setAppLoading', false);
  nprogress.done();

  return response;
};

const onError = (error) => {
  nprogress.done();
  if (error.response) {
    const { response } = error;
    if (response.status === 401 && error.response.config.url !== '/api/auth/logout') {
      store.dispatch('auth/logout');
      router.go('/sign-in');
    }
  }

  store.dispatch('global/setAppLoading', false);

  return Promise.reject(error);
};

http.interceptors.request.use(beforeRequestSuccess, beforeRequestError);
http.interceptors.response.use(onSuccess, onError);

export default http;

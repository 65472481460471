export default {
  convertArrayObjectToArrayParam(arr, param) {
    return arr.map(item => item[param]);
  },
  isObject(obj) {
    const type = typeof obj;
    // eslint-disable-next-line no-mixed-operators
    return type === 'function' || type === 'object' && !!obj;
  },
};

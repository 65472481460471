const getters = {
  getVisible(state) {
    return state.show;
  },
  getEvents(state) {
    return state.events;
  },
  getErrors(state) {
    return state.errors;
  },
  getIsShouldBeCleared(state) {
    return state.isShouldBeCleared;
  },
};

const actions = {
  setShow({ commit }, date) {
    commit('setShow', date);
  },
  setEvents({ commit }, date) {
    commit('setEvents', date);
  },
  setIsShouldBeCleared({ commit }, value) {
    commit('setIsShouldBeCleared', value);
  },
  setErrors({ commit }, value) {
    commit('setErrors', value);
  },
  clearEvents({ commit }) {
    commit('clearEvents');
  },
  clearErrors({ commit }) {
    commit('clearErrors');
  },
};

const mutations = {
  setShow(state, data) {
    state.show = data;
  },
  setEvents(state, { errors }) {
    state.events = {
      room: errors.room,
      events: errors.events,
      teachers: errors.teachers,
      students: errors.students,
      end_datetime: errors.end_datetime,
      noActiveTeachers: errors.noActiveTeachers,
      noActiveStudents: errors.noActiveStudents,
    };
  },
  setErrors(state, { errors }) {
    state.errors = {
      teachers: errors.teachers,
      students: errors.students,
      subject_id: errors.subject_id,
    };

    state.errors.emptyStudentGroup = !!Object.keys(errors)
      .find(err => err.startsWith('students.'));
  },
  setIsShouldBeCleared(state, value) {
    state.isShouldBeCleared = value;
  },
  clearEvents(state) {
    state.events = {
      room: '',
      teachers: null,
      students: null,
      events: null,
      end_datetime: [],
    };
  },
  clearErrors(state) {
    state.errors = {
      teachers: null,
      students: null,
      subject_id: null,
    };
  },
};

const state = {
  show: false,
  isShouldBeCleared: false,
  events: {
    room: '',
    teachers: null,
    students: null,
    end_datetime: [],
  },
  errors: {
    teachers: null,
    students: null,
    subject_id: null,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import Notifications from 'vue-notification';
import moment from 'moment';
import VCalendar from 'v-calendar';
import VueI18n from 'vue-i18n';
import api from '@api';
import store from '@store';
import { Ripple } from 'vuetify/lib/directives';
import events from '@utils/events';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import globalMixin from '@/mixins/global';
import App from './App.vue';

// messages
import messages from './lang';

// eslint-disable-next-line
require('moment/locale/lv');

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$api = api;
Vue.prototype.$events = events;

/* vue use */
Vue.use(Notifications);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.use(vuetify, {
  directives: {
    Ripple,
  },
});
Vue.use(VueI18n);
Vue.mixin(globalMixin);
Vue.use(Notifications);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: store.getters['global/getLang'], // set locale
  messages, // set locale messages
});

window.app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app');

const getters = {
  getGroup: state => state.group,
  isLoading: state => state.isLoading,
};

const actions = {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setGroup({ commit }, payload) {
    commit('SET_GROUP', payload);
  },
};

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_GROUP(state, group) {
    state.group = Object.assign({}, group);
  },
};

const state = {
  isLoading: false,
  group: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

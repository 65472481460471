const getters = {
  getStudents: state => state.list,
  getSelectedStudents: state => state.selectedStudents,
  isLoaded: state => state.isLoaded,
};

const actions = {
  setStudents({ commit }, { data }) {
    commit('SET_STUDENTS', data);
  },
  setSelectedStudents({ commit }, data) {
    commit('SET_SELECTED_STUDENTS', data);
  },
};

const mutations = {
  SET_STUDENTS(state, students) {
    state.list = [...students.map((group) => {
      // eslint-disable-next-line
        group.group_students = [
        ...group.group_students.map((student) => {
          // eslint-disable-next-line
            student.key = `student_${student.id}`;
          return student;
        }),
      ];
      // eslint-disable-next-line
        group.key = `group_${group.id}`;
      return group;
    })];
    state.isLoaded = true;
  },
  SET_SELECTED_STUDENTS(state, data) {
    state.selectedStudents = [...data];
  },
};

const state = {
  isLoaded: false,
  selectedStudents: [],
  list: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Helpers from '@helpers';

export default {
  showErrors(obj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if ({}.hasOwnProperty.call(obj, key)) {
        if (Helpers.isObject(obj[key])) {
          this.showErrors(obj[key]);
          return;
        }

        if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => {
            this.showErrorNotify(item);
          });
          return;
        }

        this.showErrorNotify(obj[key]);
      }
    }
  },
  showErrorNotify(text) {
    window.app.$notify({
      group: 'global',
      type: 'error',
      text,
    });
  },
};

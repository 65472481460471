const getters = {
  getUser: state => state.user,
  isLoading: state => state.isLoading,
};

const actions = {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setUser({ commit }, payload) {
    commit('SET_USER', payload);
  },
};

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_USER(state, user) {
    state.user = Object.assign({}, user);
  },
};

const state = {
  isLoading: false,
  user: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

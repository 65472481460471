import moment from 'moment';

const getters = {
  getCurrentDate(state) {
    return state.currentDate;
  },
  getListEvents(state) {
    return state.events;
  },
  getSelectedUser(state) {
    return state.userId;
  },
};

const actions = {
  setCurrentDate({ commit }, date) {
    commit('SET_CURRENT_DATE', date);
  },
  setSelectedUser({ commit }, id) {
    commit('SET_SELECTED_USER', id);
  },
  setEvents({ commit }, events) {
    commit('setEvents', events);
  },
  addEvent({ commit }, event) {
    commit('addEvent', event);
  },
  removeEvent({ commit }, eventId) {
    commit('removeEvent', eventId);
  },
};

const mutations = {
  SET_CURRENT_DATE(state, date) {
    state.currentDate = moment(date);
  },
  SET_SELECTED_USER(state, id) {
    state.userId = id;
  },
  setEvents(state, events) {
    state.events = events;
  },
  addEvent(state, event) {
    state.events.push(event);
  },
  removeEvent(state, eventId) {
    state.events.filter(item => item.id !== eventId);
  },
};

const state = {
  currentDate: moment(new Date()).set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }),
  userId: null,
  events: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const getters = {
  getGroups: state => state.list,
  getStudents: state => state.students,
  isLoading: state => state.isLoading,
};

const actions = {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setGroups({ commit }, payload) {
    commit('SET_GROUPS', payload);
  },
  setStudents({ commit }, payload) {
    commit('SET_STUDENTS', payload);
  },
};

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_GROUPS(state, groups) {
    state.list = Object.assign({}, groups);
  },
  SET_STUDENTS(state, students) {
    state.students = students;
  },
};

const state = {
  isLoading: false,
  list: {
    data: null,
    meta: null,
  },
  students: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

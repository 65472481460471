import http from '@utils/http';
import moment from 'moment';
import store from '@store';
import conts from '@/constants';

export default {
  getSchedule(
    dateFrom = store.getters['roomSchedule/getCurrentDate'].format(conts.backendDateFormat),
    rooms = store.getters['rooms/getSelectedRooms'],
    dateTo = rooms.length === 1 ? moment(dateFrom, conts.backendDateFormat).add(conts.addDays, 'days').format(conts.backendDateFormat) : dateFrom,
  ) {
    return new Promise((resolve, reject) => {
      if (rooms.length === 0) {
        return;
      }

      store.dispatch('global/setAppLoading', true);

      http.get('/api/rooms/get_schedule', {
        params: {
          rooms: [...rooms.map(room => room.id)],
          date_from: dateFrom,
          date_to: dateTo,
        },
      }).then(
        (res) => {
          store.dispatch('roomSchedule/setSelectedRooms', res.data.data);
        },
      ).catch((error) => {
        reject(error);
      }).finally(() => {
        store.dispatch('global/setAppLoading', false);
      });
    });
  },
};

const getters = {
  getUsers: state => state.users,
  getArchiveUsers: state => state.archiveUsers,
  isLoading: state => state.isLoading,
};

const actions = {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setUsers({ commit }, payload) {
    commit('SET_USERS', payload);
  },
  setArchiveUsers({ commit }, data) {
    commit('SET_ARCHIVE_USERS', data);
  },
};

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_USERS(state, users) {
    state.users = Object.assign({}, users);
  },
  SET_ARCHIVE_USERS(state, archiveUsers) {
    state.archiveUsers = Object.assign({}, archiveUsers);
  },
};

const state = {
  isLoading: false,
  users: {
    data: null,
    meta: null,
  },
  test: null,
  archiveUsers: {
    data: null,
    meta: null,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import Router from 'vue-router';
import store from '@store';
import Permissions from '@helpers/permissions';
import Roles from '@helpers/roles';

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/getIsAuthenticated']) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/getIsAuthenticated']) {
    next();
    return;
  }
  next('/sign-in');
};

function $routerCan(action, permission) {
  const permissions = store.getters['auth/getPermissions'];
  return permissions[permission] ? permissions[permission].indexOf(action) > -1 : false;
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@admin/Admin.vue'),
      beforeEnter: (to, from, next) => {
        if (Roles.isAdmin()) {
          ifAuthenticated(to, from, next);
          return;
        }
        next('/');
      },
      children: [
        {
          path: '/',
          component: () => import('@admin/Users.vue'),
          name: 'admin-main',
        },
        {
          path: 'users',
          component: () => import('@admin/Users.vue'),
          name: 'users',
          beforeEnter: (to, from, next) => {
            if (Permissions.isReadUser()) {
              ifAuthenticated(to, from, next);
              return;
            }
            next('/');
          },
        },
        {
          path: 'users/create',
          component: () => import('@admin/CreateUser.vue'),
          name: 'users.create',
          beforeEnter: (to, from, next) => {
            if (Permissions.isReadUser()) {
              ifAuthenticated(to, from, next);
              return;
            }
            next('/');
          },
        },
        {
          path: 'users/:id/edit',
          component: () => import('@admin/EditUser.vue'),
          name: 'users.edit',
          beforeEnter: (to, from, next) => {
            if (Permissions.isReadUser()) {
              ifAuthenticated(to, from, next);
              return;
            }
            next('/');
          },
        },
        {
          path: 'group-management',
          component: () => import('@admin/GroupManagement.vue'),
          name: 'group-management',
          beforeEnter: (to, from, next) => {
            if (Permissions.isReadUserGroup()) {
              ifAuthenticated(to, from, next);
              return;
            }
            next('/');
          },
        },
        {
          path: 'group-management/:id/edit',
          component: () => import('@admin/EditGroupManagement.vue'),
          name: 'group-management.edit',
          beforeEnter: (to, from, next) => {
            if (Permissions.isReadUserGroup()) {
              ifAuthenticated(to, from, next);
              return;
            }
            next('/');
          },
        },
        {
          path: 'subjects-management',
          component: () => import('@admin/SubjectsManagement.vue'),
          name: 'subjects-management',
          beforeEnter: (to, from, next) => {
            if (Permissions.isReadSubject()) {
              ifAuthenticated(to, from, next);
              return;
            }
            next('/');
          },
        },
        {
          path: 'room-groups',
          component: () => import('@admin/RoomGroups.vue'),
          name: 'room-groups',
          beforeEnter: (to, from, next) => {
            if (Permissions.isReadRoomGroup()) {
              ifAuthenticated(to, from, next);
              return;
            }
            next('/');
          },
        },
      ],
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@views/RoomSchedule.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/individual-plan',
      name: 'individual-plan',
      component: () => import('@views/IndividualPlan.vue'),
      beforeEnter: (to, from, next) => {
        if ($routerCan('r', 'individual_plan') || $routerCan('rs', 'individual_plan')) {
          ifAuthenticated(to, from, next);
          return;
        }
        next('/');
      },
    },
    {
      path: '/sign-in',
      name: 'SignIn',
      component: () => import('@views/Auth/SignIn.vue'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/sign-in-e',
      name: 'SignInE',
      component: () => import('@views/Auth/SignInEclass.vue'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: () => import('@views/Auth/SignUp.vue'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('@views/Auth/ForgotPassword.vue'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: () => import('@views/Auth/ResetPassword.vue'),
      beforeEnter: ifNotAuthenticated,
    },
  ],
});

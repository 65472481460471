const getters = {
  getSubjects: state => state.list,
  getSubjectsWithPagination: state => state.subjects,
  isLoading: state => state.isLoading,
};

const actions = {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setSubjects({ commit }, data) {
    commit('SET_SUBJECTS', data);
  },
  setSubjectsWithPagination({ commit }, data) {
    commit('SET_SUBJECTS_WITH_PAGINATION', data);
  },
  updateSubject({ commit }, data) {
    commit('UPDATE_SUBJECT', data);
  },
};

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_SUBJECTS(state, data) {
    state.list = [...data];
  },
  SET_SUBJECTS_WITH_PAGINATION(state, data) {
    state.subjects = Object.assign({}, data);
  },
  UPDATE_SUBJECT(state, data) {
    state.subjects.data.find((item) => {
      if (item.id === data.id) {
        Object.assign(item, data);
        return true;
      }
      return false;
    });
  },
};

// Todo List and Subjects need optimization because in Backend have two route for it
const state = {
  isLoading: false,
  list: [],
  subjects: {
    data: null,
    meta: null,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

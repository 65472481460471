import store from '@store';

const configAction = {
  create: 'c',
  read: 'r',
  update: 'u',
  delete: 'd',
};

export default {
  checkPermission(action, permission) {
    const permissions = store.getters['auth/getPermissions'][permission];

    if (!permissions) return false;

    const arrayAction = action.split(',');
    const arrayPermissions = permissions.split(',');

    return arrayPermissions
      .some(itemPermission => arrayAction.some(itemAction => itemAction === itemPermission));
  },
  isReadUser() {
    return this.checkPermission(configAction.read, 'user');
  },
  isCreateUpdateDeleteUser() {
    return this.checkPermission(`${configAction.create},${configAction.update},${configAction.delete}`, 'user');
  },
  isReadUserGroup() {
    return this.checkPermission(configAction.read, 'user_group');
  },
  isReadSubject() {
    return this.checkPermission(configAction.read, 'subject');
  },
  isReadRoomGroup() {
    return this.checkPermission(configAction.read, 'room_group');
  },
};

import http from '@utils/http';
import moment from 'moment';
import store from '@store';
import conts from '@/constants';

export default {
  getSchedule(
    dateFrom = store.getters['individualPlan/getCurrentDate'],
    user,
  ) {
    if (!user) {
      return;
    }
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      store.dispatch('global/setAppLoading', true);
      http.get(`/api/reservations/by_user/${user.id}/by_date`, {
        params: {
          date_from: moment(dateFrom).format(conts.backendDateFormat),
          date_to: moment(dateFrom).add(conts.addDays, 'days').format(conts.backendDateFormat),
        },
      }).then(
        ({ data }) => {
          const payload = [{ room_reservations: data.data }] || [];
          store.dispatch('individualPlan/setEvents', payload);
        },
      ).catch((error) => {
        reject(error);
      }).finally(() => {
        store.dispatch('global/setAppLoading', false);
      });
    });
  },
};

const getters = {
  getSubject: state => state.subject,
  isLoading: state => state.isLoading,
};

const actions = {
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setSubject({ commit }, payload) {
    commit('SET_SUBJECT', payload);
  },
};

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_SUBJECT(state, group) {
    state.subject = Object.assign({}, group);
  },
};

const state = {
  isLoading: false,
  subject: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const getters = {
  getTeachers: state => state.list,
  isLoaded: state => state.isLoaded,
};

const actions = {
  setTeachers({ commit }, { data }) {
    commit('SET_TEACHERS', data);
  },
};

const mutations = {
  SET_TEACHERS(state, teachers) {
    state.list = [...teachers];
    state.isLoaded = true;
  },
};

const state = {
  isLoaded: false,
  list: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default {
  test: 'test',
  backendDateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  timeFormat: 'HH:mm',
  addDays: 6,
  calendarPickerMods: {
    single: 'single',
    range: 'range',
  },
  calendarViews: {
    day: 'day',
    week: 'week',
  },
};

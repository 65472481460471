// messages.js
import admin from './admin';

export default {
  admin: { ...admin },
  room_schedule: 'Room schedule',
  teachersAndStudents: 'Teachers and Students',
  individual_plan: 'Individual plan',
  monday: 'Monday',
  thuesday: 'Thuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  search: 'Search',
  room: 'Room',
  rooms: 'Rooms',
  students: 'Students',
  status: 'Status',
  teachers: 'Teachers',
  name: 'Name',
  event: 'Event',
  eventName: 'Event name',
  category: 'Category',
  select: 'Select',
  location: 'Location',
  period: 'Period',
  single: 'Single',
  recurring: 'Recurring',
  date: 'Date',
  from: 'From',
  until: 'Until',
  to: 'To',
  create: 'Create',
  edit: 'Edit',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  time: 'Time',
  ohSorry: 'Oh sorry...',
  goBackToUpdate: 'Go back to update',
  fieldIsRequired: 'Field is required',
  fieldIsNotValid: 'Field is not valid',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  jule: 'Jule',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  weekly: 'Weekly',
  everySecondWeek: 'Every second week',
  everyThirdWeek: 'Every third week',
  monthly: 'Monthly',
  add: 'Add',
  saveAll: 'Save All',
  saveClose: 'Save and Close',
  dataCanNotBeSaved: 'Data will not be saved. Do you really want to leave event?',
  doYouWantToChangeAListRecurrence: 'Do you want to change a list of recurrence events?',
  doYouReallyWantToDeleteEvents: 'Do you really want to delete events?',
  inRoom: 'in Room',
  lessonShouldEndBefore9Pm: 'Lesson should end before 21 o\'clock.',
  roomReservationError: 'Room reserved for these dates, choose another place or wait until the room is free.',
  studentReservationError: 'Students will be at other readings, choose another time.',
  studentReservationNoStudentsInGroupError: 'There is no students in selected groups',
  teacherReservationError: 'Teachers will be in other classes, choose another time or arrange with the teacher.',
  dateFrom: 'Date from',
  dateUntil: 'Date until',
  timeFrom: 'Time from',
  timeTo: 'Time to',
  description: 'Description',
  eventDescription: 'Event description',
  minTime: 'Time should be after',
  maxTime: 'Time should be before',
  // new words
  signIn: 'Sign in',
  signUp: 'Sign Up',
  signOut: 'Sign Out',
  submit: 'Submit',
  email: 'Email',
  password: 'Password',
  newPassword: 'New Password',
  resetPassword: 'Reset password',
  forgotPassword: 'Forgot password',
  emailError: 'Email field is not valid',
  passwordError: '6-10 characters',
  login: 'Login',
  confirmPassword: 'Confirm password',
  passwordNotMatch: 'Passwords do not match',
  surname: 'Surname',
  loginSuccess: 'Login was successful',
  loginFail: 'Login failed, please try again',
  registerSuccess: 'Register was successful',
  emailWasSentSuccessful: 'Email was sent successfully. Please check your mail.',
  passwordResetSuccessful: 'Password was successfully updated.',
  signInByEclass: 'Sign in by E-class.',
  onlyAplhWithSpaceWithDash: 'Accepts alphabet, space and "-.,"',
  phoneNumberNotValid: 'Phone number is invalid',
  subject: 'Subject',
};

import moment from 'moment';

const getters = {
  getCurrentDate(state) {
    return state.currentDate;
  },
  getAppLoading(state) {
    return state.isAppLoading;
  },
  getSelectedRooms(state) {
    return state.selectedRooms;
  },
};

const actions = {
  setCurrentDate({ commit }, date) {
    commit('SET_CURRENT_DATE', date);
  },
  setSelectedRooms({ commit }, rooms) {
    commit('setSelectedRooms', rooms);
  },
  setAppLoading({ commit }, value) {
    commit('SET_APP_LOADING', value);
  },
};

const mutations = {
  SET_CURRENT_DATE(state, date) {
    state.currentDate = moment(date);
  },
  SET_APP_LOADING(state, value) {
    state.isAppLoading = value;
  },
  setSelectedRooms(state, value) {
    state.selectedRooms = value;
  },
};

const state = {
  currentDate: moment(new Date()).set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }),
  selectedRooms: [],
  isAppLoading: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import http from '@utils/http';

export default {
  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      http.post('/api/auth/forgot-password', { email }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  resetPassword(payload) {
    return new Promise((resolve, reject) => {
      http.post('/api/auth/reset-password', payload).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};

import http from '@utils/http';
import store from '@store';
import HelpersErrors from '@helpers/errors';

export default {
  fetchData() {
    return new Promise((resolve, reject) => {
      http.get('/api/subjects').then(
        ({ data }) => {
          store.dispatch('subjects/setSubjects', data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  getSubjects(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('subjects/setLoading', true);
      http.get('/api/admin/subjects', { params }).then((res) => {
        store.dispatch('subjects/setSubjectsWithPagination', res.data);
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('subjects/setLoading', false));
    });
  },
  getSubject(id) {
    return new Promise((resolve, reject) => {
      store.dispatch('subject/setLoading', true);
      http.get(`/api/admin/subjects/${id}`).then((res) => {
        store.dispatch('subject/setSubject', res.data.data);
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('subject/setLoading', false));
    });
  },
  updateSubject(params, subjectId) {
    return new Promise((resolve, reject) => {
      store.dispatch('subject/setLoading', true);
      http.put(`/api/admin/subjects/subject/${subjectId}`, params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.subject_was_updated'),
        });
        resolve(res);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('subject/setLoading', false));
    });
  },
  createSubject(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('subject/setLoading', true);
      http.post('/api/admin/subjects/subject/create', params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.subject_was_created'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('subject/setLoading', false));
    });
  },
  removeSubject(id, params) {
    return new Promise((resolve, reject) => {
      store.dispatch('subject/setLoading', true);
      http.delete(`/api/admin/subjects/subject/${id}`, { params }).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.subject_was_removed'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('subject/setLoading', false));
    });
  },
  removeMultiplySubject(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('subjects/setLoading', true);
      http.delete('/api/admin/subjects/subject/multiply', { params }).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.subject_were_removed'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('subjects/setLoading', false));
    });
  },
};

export default {
  user: 'User',
  users: 'Users',
  archive: 'Archive',
  group_management: 'Group Management',
  subjects_management: 'Subjects Management',
  room_groups: 'Room Groups',
  id: 'Id',
  role: 'Role',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  personal_code: 'Personal code',
  actions: 'Actions',
  user_was_updated: 'User was updated',
  user_was_created: 'User was created',
  user_was_removed: 'User was removed',
  user_were_removed: 'Users were removed',
  please_select_users: 'Please select users',
  please_select_groups: 'Please select groups',
  please_select_subjects: 'Please select subjects',
  delete: 'Delete',
  are_you_sure_you_want_to_delete_a_user: 'Are you sure you want to delete a user ?',
  are_you_sure_you_want_to_delete_a_users: 'Are you sure you want to delete the users ?',
  this_student_is_participant_of_active_event: 'This student is participant of active event',
  user_phone_number: 'User phone number',
  user_middlename: 'User Middlename',
  create_user: 'Create User',
  update_user: 'Update User',
  group_students: 'Group Students',
  edit_group_management: 'Edit Group Management',
  ready_to_attach_participants: 'Ready to attach participants',
  assigned_participants: 'Assigned participants',
  save_name: 'Save Name',
  group_was_updated: 'Group was updated',
  group_was_removed: 'Group was removed',
  assigned: 'Assigned',
  detach: 'Detach',
  students_were_assigned: 'Students were assigned',
  students_were_detached: 'Students were detached',
  are_you_sure_you_want_to_delete_a_group: 'Are you sure you want to delete a group ?',
  are_you_sure_you_want_to_delete_a_subject: 'Are you sure you want to delete a subject ?',
  subject_was_updated: 'Subject was updated',
  subject_was_created: 'Subject was created',
  subject_was_removed: 'Subject was removed',
  subject_were_removed: 'Subjects were removed',
  one_or_several_groups_was_removed_already_please_reload_page: 'One or several groups was removed already, please reload page',
};

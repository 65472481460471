const getters = {
  getCategories: state => state.list,
  isLoaded: state => state.isLoaded,
};

const actions = {
  setCategories({ commit }, data) {
    commit('SET_CATEGORIES', data);
  },
};

const mutations = {
  SET_CATEGORIES(state, data) {
    state.list = [...data];
  },
};

const state = {
  isLoaded: false,
  list: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

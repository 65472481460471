import api from '@api';

const getters = {};

const actions = {
  forgotPassword({ commit }, { email }) {
    commit('FORGOT_PASSWORD_REQUEST');
    return new Promise((resolve, reject) => {
      api.password.forgotPassword(email).then(({ data }) => {
        resolve(data);
        commit('FORGOT_PASSWORD_SUCCESS');
      }).catch((e) => {
        console.log(e);
        commit('FORGOT_PASSWORD_FAILURE');
        reject(e);
      });
    });
  },
  resetPassword({ commit }, payload) {
    commit('RESET_PASSWORD_REQUEST');
    return new Promise((resolve, reject) => {
      api.password.resetPassword(payload).then(({ data }) => {
        resolve(data);
        commit('RESET_PASSWORD_SUCCESS');
      }).catch((e) => {
        console.log(e);
        commit('RESET_PASSWORD_FAILURE');
        reject(e);
      });
    });
  },
};

const mutations = {
  FORGOT_PASSWORD_REQUEST() {},
  FORGOT_PASSWORD_SUCCESS() {},
  FORGOT_PASSWORD_FAILURE() {},
  RESET_PASSWORD_REQUEST() {},
  RESET_PASSWORD_SUCCESS() {},
  RESET_PASSWORD_FAILURE() {},
};

const state = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

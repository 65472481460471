import http from '@utils/http';
import store from '@store';

export default {
  fetchData() {
    return new Promise((resolve, reject) => {
      http.get('/api/teachers').then(
        ({ data }) => {
          store.dispatch('teachers/setTeachers', data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};

import en from './en';
import lv from './lv';

export default {
  en: {
    message: en,
  },
  lv: {
    message: lv,
  },
};

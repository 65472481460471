import http from '@utils/http';

export default {
  auth() {
    return new Promise((resolve, reject) => {
      http.get('/api/auth/me').then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  authEclass(code) {
    return new Promise((resolve, reject) => {
      http.post('/api/auth/login-using-e-class', { code }).then(
        (response) => {
          console.log(response);
          resolve(response);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  permissions() {
    return new Promise((resolve, reject) => {
      http.get('/api/permissions').then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  login(payload) {
    return new Promise((resolve, reject) => {
      http.post('/api/auth/login', payload).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  register(payload) {
    return new Promise((resolve, reject) => {
      http.post('/api/auth/register', payload).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      http.post('/api/auth/logout').then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};

const getters = {
  getRoles: state => state.list,
  isLoaded: state => state.isLoaded,
};

const actions = {
  setRoles({ commit }, { data }) {
    commit('SET_ROLES', data);
  },
};

const mutations = {
  SET_ROLES(state, roles) {
    state.list = [...roles];
    state.isLoaded = true;
  },
};

const state = {
  isLoaded: false,
  list: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

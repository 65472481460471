import http from '@utils/http';
import store from '@store';

export default {
  fetchData() {
    return new Promise((resolve, reject) => {
      http.get('/api/categories').then(
        ({ data }) => {
          store.dispatch('categories/setCategories', data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
